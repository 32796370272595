<template>
  <div class="main">
    <van-image
      v-if="storeImg"
      fit="cover"
      class="exchange-bg"
      :src="storeImg"
    />
    <van-image
      v-else
      fit="cover"
      class="exchange-bg"
      :src="require('../../static/images/v3/top-header.png')"
    />

    <!-- 个人信息模块 -->
    <div class="top-userinfo-header">
      <div class="top-userinfo-header-content">
        <div>
          <van-image
            fit="cover"
            class="top-userinfo-header_avatar"
            :src="require('../../static/images/v4/mine_def_touxiang_3x.png')"
          />
        </div>
        <div class="top-userinfo-header_detail">
          <div class="top-userinfo-header_detail_panel">
            <div class="top-userinfo-header_detail_phone">
              {{ formatMobile(account) || "****" }}
            </div>
            <div
              class="top-userinfo-header_detail_logout"
              @click="showActionSheet = true"
            >
              退出
            </div>
          </div>
          <div class="top-userinfo-header_detail_points">
            移动积分：{{ points }}
          </div>
        </div>
      </div>
      <div class="top-userinfo-header_ex" @click.stop="handleRecord">
        兑换记录
      </div>
    </div>

    <!-- 券 -->
    <div style="margin-top: 15px; position: relative">
      <div class="coupon-bg">
        <van-image
          fit="cover"
          class="coupon-bg-header"
          :src="require('../../static/images/v3/panel-header.png')"
        />
        <!-- <div class="coupon-bg-panel">
          <van-image
            fit="cover"
            style="border-radius: 5px; overflow: hidden"
            :src="require('../../static/images/v3/download-1.jpg')"
          />
        </div> -->

        <div style="margin-top: 15px; width: 100%">
          <div
            class="coupon-change-item"
            v-for="(item, index) in productList"
            :key="index"
          >
            <div>
              <van-image
                fit="cover"
                class="coupon-change-item-img"
                :src="item.goodsImg"
              />
            </div>
            <div class="coupon-change-item-points">
              {{ item.goodsPrice }}积分
            </div>
            <div class="coupon-change-item-step">
              <van-stepper
                v-model="item.exchangeCount"
                :min="0"
                theme="round"
                input-width="30px"
                button-size="20px"
                :disable-plus="item.enablePlus"
                disable-input
                @plus="onPlus(item, index)"
                @minus="onMinus(item, index)"
                @change="onStepChange($event, item, index)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="exchange-rules">
      <div class="exchange-rules-panel">
        <div class="exchange-rules-panel-title">兑换规则</div>
        <div class="exchange-rules-info">
          *本兑换仅支持“移动用户”;仅支持移动消费积分兑换，移动营销积分不参与兑换。<br />
          1.客户可在“微信APP-我-卡包-券和礼品卡”中查看已获得的代金券;如若已经使用或过期，可在“微信APP-我-卡包-右上角三个点.常见问题-历史卡券列表”中查看。<br />
          2.使用微信付款时，2元券满2.01元自动抵扣，10元券满10.01元自动抵扣，20元券满20.01元自动抵扣，同卡种可多张叠加使用，叠加金额不得高于微信付款费用。<br />
          3.代金券有效期为兑换后60天内有效，请妥善安排再兑换。<br />
          4.代金券不可转发，过期作废，不再发放。<br />
          5.兑换成功后，扣除积分无法退回。<br />
          6.点击查询我的积分，即同意授权第三方获取您的手机号信息。<br />
          7.若在兑换页面一直转圈加载无反应，或者积分显示为0，代表不支持手机卡所在的归属地，移动用户现有以下归属地暂不支持积分兑换：广西、重庆、辽宁、河南、江西、云南、湖北、安徽，请知晓。<br />
          8.活动咨询热线：4001096138-9。<br />
          9.活动方对消费券的使用规则有最终解释权。<br />
        </div>
      </div>
    </div>

    <van-submit-bar
      id="CONFIRM"
      :price="subbarTotalPoints"
      text-align="left"
      :decimal-length="0"
      currency=""
      :tip="`${exchangeTip}${exchangeTenTip}`"
      label="使用积分："
      button-text="兑换领取"
      button-color="linear-gradient(to right, #fab768, #fe6831)"
      @submit="handleConfirm"
    />

    <van-popup
      v-model="showPopup"
      position="center"
      closeable
      :close-on-click-overlay="false"
      :style="{ minHeight: '440px', width: '80%' }"
      round
    >
      <div class="pop">
        <!-- header -->
        <div class="pop-header">
          <div class="pop-header-title">兑换确认</div>
        </div>

        <!-- step -->
        <div class="pop-process">
          <div class="pop-process-step">
            <van-image
              fit="cover"
              class="pop-process-step-icon"
              :src="require('../../static/images/v4/step.png')"
            />
          </div>

          <div class="pop-process-step-title">
            <div>中国移动<br />积分</div>
            <div style="margin-left: -10px">畅由<br />积分</div>
            <div>兑换<br />立减金</div>
          </div>

          <div class="pop-process-step-title">
            <div class="pop-process-step-points" style="margin-left: 0px">
              {{ totalPoints }}积分
            </div>
            <div class="pop-process-step-points">{{ cyTotalPoints }}积分</div>
            <div class="pop-process-step-points">立减金</div>
          </div>
        </div>

        <div class="pop-header-tiplab">领券明细</div>

        <div class="pop-header-tipval">
          <!-- 短信验证码已发送，请注意查收由中国移动积分商城发送的短信验证码。 -->
          {{ exchangeTip }}{{ exchangeTenTip }}
        </div>

        <!-- button -->
        <div style="padding-top: 10px">
          <div class="pop-sms">
            <div class="pop-sms-title">
              短信验证码已发送至{{
                account
              }}，请注意查收由中国移动积分商城发送的短信验证码。
            </div>
          </div>
          <div class="pop-sms-info">
            <van-image
              fit="cover"
              class="pop-sms-info-icon"
              :src="require('../../static/images/vcode.png')"
            />
            <van-field
              v-model="smsCode"
              size="large"
              placeholder="请输入验证码"
              class="pop-sms-info-code"
              maxlength="6"
              clearable
            >
              <template #button>
                <van-button
                  class="pop-sms-info-smsbtn"
                  @click="handleSendSMS"
                  :disabled="isCountDown"
                  >{{ countdownLab }}</van-button
                >
              </template>
            </van-field>
          </div>
        </div>

        <div class="pop-sms-submit">
          <van-button
            id="PAY"
            class="pop-sms-submit-btn"
            round
            color="linear-gradient(to right, #fab768, #fe6831)"
            @click="handlePayConfirm"
          >
            确认兑换
          </van-button>
        </div>
      </div>
    </van-popup>

    <van-popup
      v-model="showResPopup"
      position="center"
      :close-on-click-overlay="false"
      :style="{ minHeight: '360px', width: '80%' }"
      round
    >
      <div class="pop-result">
        <van-image
          fit="cover"
          class="pop-result-header"
          :src="require('../../static/images/success_icon.png')"
        />

        <van-image
          fit="cover"
          class="pop-result-icon"
          :src="require('../../static/images/success_gou_icon.png')"
        />

        <van-image
          fit="cover"
          class="pop-result-lab"
          :src="require('../../static/images/success-icon.png')"
        />

        <div class="pop-result-submit" @click="handleOk">确认</div>
      </div>
    </van-popup>

    <!-- 退出登录 -->
    <van-action-sheet
      v-model="showActionSheet"
      :actions="actions"
      cancel-text="取消"
      description="确认退出登录？"
      close-on-click-action
      @select="onActionSheetSelect"
    />
  </div>
</template>

<script>
import {
  Row,
  Col,
  NavBar,
  Image,
  Button,
  Field,
  Popup,
  Step,
  Steps,
  PullRefresh,
  SubmitBar,
  Stepper,
  Icon,
  Divider,
  RadioGroup,
  Radio,
  ActionSheet,
  Empty,
} from "vant";
import {
  memberInfo,
  queryCmccBalance,
  queryCmccBalanceNew,
  getProduct,
  placeOrderByCode,
  dectOrder,
  sendCmccSms,
  getStoreChannelInfo,
  getStoreInfo
} from "@/api/global";
import { throws } from "assert";

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [NavBar.name]: NavBar,
    [Image.name]: Image,
    [Button.name]: Button,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [PullRefresh.name]: PullRefresh,
    [SubmitBar.name]: SubmitBar,
    [Stepper.name]: Stepper,
    [Icon.name]: Icon,
    [Divider.name]: Divider,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [ActionSheet.name]: ActionSheet,
    [Stepper.name]: Stepper,
    [Empty.name]: Empty
  },

  data() {
    return {
      account: null,
      points: 0,
      exchangeVal: 0,
      showPopup: false,
      showResPopup: false,
      showConfirmPopup: false,

      smsCode: null,
      active: 4,
      stepVal: 0,
      buyCount: 1,
      needPoints: 0,
      productInfo: {},
      reqOrderId: null,
      isCountDown: false,
      countdownLab: "获取验证码",
      timer: null,

      isProduct: true,

      isLoading: false,
      loadingMore: false,
      finished: false,
      productList: [],
      popupProduct: {},
      stepCountVal: 1,

      radio: "1",

      storeInfo: {},

      showActionSheet: false,
      actions: [{ name: "确认退出", color: "#ee0a24" }],

      currentPoints: 0,
      totalPoints: 0,
      cyTotalPoints: 0,
      subbarTotalPoints: 0,

      firstCount: 0,
      firstPoints: 480,
      enabledFirst: true,
      twoCount: 0,
      twoPoints: 1320,
      enabledTwo: true,
      threeCount: 0,
      threePoints: 2160,
      enabledThree: true,
      fourCount: 0,
      fourPoints: 4320,
      enabledFour: true,

      exchangeTip: "",
      exchangeTenTip: "",
      testPoints: 0,

      storeImg: null,
    };
  },

  created() {
    window.merId = this.$route.query.merId;
    window.storeId = this.$route.query.storeId;
    window.channelCode = this.$route.query.channelCode;

    this.account = localStorage.getItem("ljj_mobile");
    console.log("code >>> ", this.$route.query.channelCode);
    
    this.getStoreInfoData()
  },

  mounted() {
    const _this = this;
    memberInfo()
      .then((res) => {
        console.log("memberInfo >>> ");
        _this.initLoadData();
      })
      .catch((err) => {
        if (err.status == 401) {
          _this.$toast("登录失效，请重新登录");

          let query = _this.$route.query;
          if (_this.$route.query.channelCode) {
            query.channelCode = _this.$route.query.channelCode;
          }

          if (_this.$route.query.id) {
            query.id = _this.$route.query.id;
          }

          if (_this.$route.query.merId) {
            query.merId = _this.$route.query.merId;
          }

          if (_this.$route.query.storeId) {
            query.storeId = _this.$route.query.storeId;
          }

          if (_this.$route.query.ddback) {
            query.ddback = _this.$route.query.ddback;
          }

          _this.$router.replace({
            path: "/",
            query,
          });
        }
      });
  },

  computed: {
    formatMobile() {
      return (val) => {
        return val.substring(0, 3) + "****" + val.substr(val.length - 4);
      };
    },

    submitBarText() {
      const count = this.checkedGoods.length;
      return "结算" + (count ? `(${count})` : "");
    },

    totalPrice() {
      return this.goods.reduce(
        (total, item) =>
          total + (this.checkedGoods.indexOf(item.id) !== -1 ? item.price : 0),
        0
      );
    },
  },

  methods: {
    async initLoadData() {
      let data = {};
      if (this.$route.query.channelCode) {
        data.channelCode = this.$route.query.channelCode;
      }

      if (this.$route.query.id) {
        data.transCode = this.$route.query.id;
      }

      if (this.$route.query.merId) {
        data.merId = this.$route.query.merId;
      }

      if (this.$route.query.storeId) {
        data.storeId = this.$route.query.storeId;
      }

      if (this.$route.query.ddback) {
        data.ddback = this.$route.query.ddback;
      }

      data.fingerprint = `${new Date().getTime()}`;
      data.sessionid = `${new Date().getTime()}`;

      let balanceResp = await queryCmccBalanceNew(data);

      try {
        console.log("1122 queryCmccBalanceNew >>> ", balanceResp);
        if (balanceResp) {
          console.log("queryCmccBalanceNew >>> ", balanceResp);
          if (balanceResp) {
            if (balanceResp.resultCode == "0000") {
              this.points = balanceResp.points || 0;
              this.currentPoints = balanceResp.points;
            } else if (balanceResp.resultCode == "1114") {
              // window.location.reload()

              balanceResp = await queryCmccBalanceNew(data);
              if (balanceResp.resultCode == "0000") {
                this.points = balanceResp.points || 0;
                this.currentPoints = balanceResp.points;
              }
            }
          }
        }
      } catch (error) {
        console.log("queryCmccBalanceNew err >>>>", error);
      }

      // data.fingerprint = window.blackbox;
      // data.sessionid = window.sessionId;

      // let balanceResp = await queryCmccBalanceBind(data);
      // try {
      //   if (balanceResp) {
      //     console.log("queryCmccBalanceBind >>> ", balanceResp);
      //     this.points = balanceResp.points || 0;
      //     this.currentPoints = balanceResp.points || 0;
      //   }
      // } catch (err) {
      //   console.log('err >>>> ', err)
      //   if (err.code == 1008611) {
      //     window.location.reload();
      //   }
      // }

      let productResp = await getProduct({
        pageNo: 1,
        pageSize: 1000,
        sortField: 'points',
        sortRule: 'asc'
      });

      if (productResp) {
        console.log("res >>>> ", productResp);
        if (productResp.records && productResp.records.length > 0) {
          this.productList = productResp.records || [];
          this.productList.map((item) => {
            item.exchangeCount = 0;
            item.exchangePoints = 0;
            item.enablePlus = true;
            return;
          });

          this.calcExchangeVal();
        }

        this.isLoading = false;
      }
    },
    // 获取门店信息
    getStoreInfoData() {
      getStoreInfo().then(res => {
        if (res.storeImg && res.storeImg.length > 0) {
          this.storeImg = res.storeImg
        }
      })
    },
    // 计算
    calcExchangeVal() {
      let list = Object.assign([], this.productList);
      list.sort((a, b) => b.goodsPrice - a.goodsPrice);

      for (let index = 0; index < list.length; index++) {
        const item = list[index];
        if (this.currentPoints >= item.goodsPrice) {
          item.exchangeCount = parseInt(this.currentPoints / item.goodsPrice);
          item.exchangePoints = item.exchangeCount * item.goodsPrice;
          this.currentPoints = this.currentPoints - item.exchangePoints;
        }
      }

      this.checkEabledPlus();
      // this.handleConfirm();
    },

    onPlus(item, index) {
      // this.currentPoints -= this.firstPoints;
      if (this.currentPoints >= item.goodsPrice) {
        this.productList[index].exchangeCount++;
        this.productList[index].exchangePoints =
          this.productList[index].exchangeCount * item.goodsPrice;
        this.currentPoints = this.currentPoints - item.goodsPrice;

        this.productList = Object.assign([], this.productList);
      } else {
        this.$toast("积分不足");
      }
    },
    onMinus(item, index) {
      // this.currentPoints += this.firstPoints;
      if (this.productList[index].exchangeCount <= 0) {
        return;
      }

      this.currentPoints = this.currentPoints + item.goodsPrice;
      this.productList[index].exchangeCount--;
      item.exchangePoints =
        this.productList[index].exchangeCount * item.goodsPrice;

      this.productList = Object.assign([], this.productList);
    },
    onStepChange(e, item, index) {
      this.checkEabledPlus(item, index);
    },

    checkEabledPlus(item, index) {
      let sumPoints = 0;
      let sumCount = 0;

      this.exchangeTip = "";
      this.productList.map((el) => {
        el.enablePlus = parseInt(this.currentPoints / el.goodsPrice) <= 0;
        sumPoints += el.exchangePoints;
        sumCount += el.exchangeCount;

        this.configExchangeTip(el);
        return el;
      });

      this.productList = Object.assign([], this.productList);

      this.totalPoints = sumPoints;
      this.subbarTotalPoints = sumPoints * 100;
      this.cyTotalPoints = parseInt(sumPoints / 120) * 100;
    },

    configExchangeTip(item) {
      if (item.exchangeCount > 0) {
        this.exchangeTip += item.goodsName + ` x ${item.exchangeCount}; `;
      }
    },

    onRefresh() {
      this.getProduct();
    },

    onLoad() {
      this.getProduct();
    },

    formatPrice(price) {
      return (price / 100).toFixed(2);
    },

    startCountDown() {
      const _t = this;
      let initVal = 60;
      this.timer = setInterval(() => {
        if (initVal <= 0) {
          _t.isCountDown = false;
          _t.countdownLab = "获取验证码";

          clearInterval(_t.timer);
          return;
        }

        initVal -= 1;
        _t.countdownLab = initVal + "s";
      }, 1000);
    },

    // 选择兑换商品
    handleProduct(item) {
      this.popupProduct = Object.assign({}, item);
      this.showConfirmPopup = true;
    },

    handleBack() {
      this.$router.back();
    },

    handleOk() {
      this.$router.replace("/");
    },

    handleRecord() {
      let query = this.$route.query;
      if (this.$route.query.channelCode) {
        query.channelCode = this.$route.query.channelCode;
      }

      if (this.$route.query.id) {
        query.id = this.$route.query.id;
      }

      if (this.$route.query.merId) {
        query.merId = this.$route.query.merId;
      }

      if (this.$route.query.storeId) {
        query.storeId = this.$route.query.storeId;
      }

      if (this.$route.query.ddback) {
        query.ddback = this.$route.query.ddback;
      }
      this.$router.push({
        path: "/record",
        query,
      });
    },

    onActionSheetSelect(e, i) {
      if (i == 0) {
        this.logout();
      }
    },

    logout() {
      localStorage.removeItem("ljj_login_token");
      localStorage.removeItem("ljj_mobile");

      let query = this.$route.query;
      if (this.$route.query.channelCode) {
        query.channelCode = this.$route.query.channelCode;
      }

      if (this.$route.query.id) {
        query.id = this.$route.query.id;
      }

      if (this.$route.query.merId) {
        query.merId = this.$route.query.merId;
      }

      if (this.$route.query.storeId) {
        query.storeId = this.$route.query.storeId;
      }

      if (this.$route.query.ddback) {
        query.ddback = this.$route.query.ddback;
      }

      this.$router.replace({
        path: "/exchange",
        query,
      });
    },

    handleSendSMS() {
      if (!this.reqOrderId) {
        this.$toast("无法查询订单信息，请稍后再试");
        return;
      }

      sendCmccSms({
        reqOrderId: this.reqOrderId,
      }).then(() => {
        this.isCountDown = true;
        this.startCountDown();
      });
    },

    // 下单
    handleConfirm() {
      console.log("sid >>> ", window.sessionId, window.blackbox);

      if (!window.sessionId) {
        this.$toast("服务初始异常");
        return;
      }

      if (!window.blackbox) {
        this.$toast("服务初始异常");
        return;
      }

      let filters = this.productList.filter((item) => {
        return item.exchangeCount == 0;
      });
      if (filters.length == this.productList.length) {
        this.$toast("请选择兑换礼包数量");
        return;
      }

      let goodsInfo = [];
      this.productList.forEach((item) => {
        if (item.exchangeCount > 0) {
          goodsInfo.push({
            goodsNo: item.goodsNo,
            goodsNum: item.exchangeCount,
          });
        }
      });

      let data = {
        fingerprint: window.blackbox,
        goodsInfo,
        totalPoints: this.totalPoints,
        sessionid: window.sessionId,
      };

      if (this.$route.query.channelCode) {
        data.channelCode = this.$route.query.channelCode;
      }

      if (this.$route.query.merId) {
        data.merId = this.$route.query.merId;
      }

      if (this.$route.query.storeId) {
        data.storeId = this.$route.query.storeId;
      }

      if (this.$route.query.id) {
        data.transCode = this.$route.query.id;
      }
      
      if (this.$route.query.code) {
        data.code = this.$route.query.code;
      }

      placeOrderByCode(data).then((res) => {
        if (res) {
          this.reqOrderId = res.requestId;
          this.isCountDown = true;
          this.startCountDown();

          this.showPopup = true;
        }
      });
    },

    handlePayConfirm() {
      if (!this.reqOrderId) {
        this.$toast("无法查询订单信息，请稍后再试");
        return;
      }

      if (!this.smsCode || this.smsCode.length == 0) {
        this.$toast("请输入验证码");
        return;
      }

      dectOrder({
        fingerprint: window.blackbox,
        reqOrderId: this.reqOrderId,
        smsCode: this.smsCode,
        sessionid: window.sessionId,
      }).then(() => {
        let query = this.$route.query;
        query.orderId = this.reqOrderId;

        if (this.$route.query.channelCode) {
          query.channelCode = this.$route.query.channelCode;
        }

        if (this.$route.query.merId) {
          query.merId = this.$route.query.merId;
        }

        if (this.$route.query.storeId) {
          query.storeId = this.$route.query.storeId;
        }

        if (this.$route.query.id) {
          query.id = this.$route.query.id;
        }

        if (this.$route.query.ddback) {
          query.ddback = this.$route.query.ddback;
        }

        query.cyPoints = this.cyTotalPoints;
        query.productTip = `${this.exchangeTip}${this.exchangeTenTip}`;

        this.$router.push({
          path: "/res",
          query,
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  background-color: #ffd9ae;
  min-height: 100vh;
  padding-bottom: 120px;
}

:deep(.van-nav-bar__content) {
  background-color: #006bd9;
}

:deep(.van-nav-bar__title) {
  color: #fff !important;
}

:deep(.van-icon) {
  color: #fff !important;
}

.content {
  position: relative;
  margin-top: 0px;
  padding-top: 5px;

  &-header {
    width: 100%;
    height: 200px;
  }
}

.user-info {
  background-color: #fff;
  background: linear-gradient(to right, #fdfffe, #fbf9e0);
  border-radius: 10px;
  margin: 20px 15px 10px;
  padding: 20px;

  &-header {
    display: flex;
    align-items: center;
  }
}

.store-info {
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  &-name {
    font-size: 15px;
    font-weight: 600;
    color: #333;
  }

  &-address {
    font-size: 12px;
    color: #999;
    padding-top: 2px;
  }
}

.account-mobile {
  font-size: 22px;
  font-weight: 600;
  color: #333;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &-logout {
    margin-right: -8px;
    font-size: 14px;
    color: #006bd9;
    font-weight: 600;
  }
}

.account {
  width: 50px;
  height: 50px;
  padding-right: 10px;

  &-exchange-tip {
    color: #999;
    font-size: 13px;
    padding-top: 8px;
  }
}

// .account-panel {
//   background-color: #f5f5f5;
//   padding: 10px;
//   margin: 5px;
// }

.account-descr {
  color: #666;
  font-size: 16px;
  padding-top: 8px;
  display: flex;
  align-items: center;

  &-picon {
    width: 15px;
    height: 15px;
  }

  &-points {
    color: #006bd9;
    font-size: 24px;
    font-weight: 600;
  }
}

.points {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;

  &-title {
    display: flex;
    align-items: center;

    &-icon {
      width: 20px;
      height: 15px;
    }

    &-lab {
      padding-left: 5px;
      font-size: 12px;
      color: rgba(255, 255, 255, 1);
    }
  }

  &-count {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    padding-top: 5px;
  }
}

.pop {
  position: relative;
  height: 100%;
  background: linear-gradient(to right, #fdfffe, #fbf9e0);

  &-header {
    color: #333;
    height: 68px;
    width: 100%;

    &-title {
      width: 100%;
      height: 68px;
      line-height: 68px;
      text-align: center;
      font-size: 17px;
      font-weight: 600;
    }

    &-tiplab {
      padding: 20px 20px 0;
      font-size: 14px;
      color: #333;
      font-weight: 600;
    }

    &-tipval {
      padding: 5px 20px 0;
      font-size: 14px;
      color: #df5214;
    }
  }

  &-process {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-step {
      display: flex;
      justify-content: center;
      width: 100%;

      &-icon {
        width: 70%;
      }

      &-title {
        width: 75%;
        display: flex;
        justify-content: space-between;
        text-align: center;
        font-size: 12px;
        color: #333;
        padding-top: 10px;
      }

      &-points {
        color: #df5214;
      }
    }
  }

  &-sms {
    &-title {
      padding: 10px 20px 0;
      font-size: 14px;
      color: #333;
      font-weight: 600;
    }

    &-info {
      display: flex;
      align-items: center;
      border-bottom: 0.5px solid #ddd;
      padding: 15px 0;

      &-icon {
        width: 25px;
        height: 25px;
        padding-left: 20px;
      }

      &-code {
        font-size: 17px;
        font-weight: 600;
        background-color: transparent;
        padding: 0 15px;
      }
    }

    &-submit {
      padding: 30px 0;
      display: flex;
      justify-content: center;

      &-btn {
        width: 80%;
        box-shadow: 2px 3px 5px 0px #fe6831;
      }
    }
  }
}

.pop-result {
  position: relative;

  &-header {
    width: 100%;
    z-index: 99;
  }

  &-icon {
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 199;
  }

  &-lab {
    position: absolute;
    top: 250px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 199;
  }

  &-submit {
    position: absolute;
    width: 100%;
    bottom: -120px;
    font-size: 14px;
    color: #666;
    text-align: center;
  }
}

:deep(.van-field__control) {
  color: #006bd9;
}

.info-exchange-panel {
  padding-top: 15px;
  display: flex;
  justify-content: center;
}
.info-exchange-title {
  width: 140px;
}

.product-item {
  background-color: #fff;
  width: 46%;
  display: inline-block;
  margin-left: 10px;
  margin-top: 5px;
  border-radius: 8px;
  overflow: hidden;

  &-img {
    width: 100%;
    height: 140px;
  }

  &-title {
    padding: 5px 10px 0;
    font-size: 14px;
  }

  &-points {
    font-size: 14px;
    color: #fbae54;
    padding: 0 10px 5px;
  }

  &-btn {
    padding: 10px 10px 15px;
    font-size: 12px;
    text-align: center;
  }

  &-span {
    padding: 6px 14px;
    color: #fff;
    border-radius: 5px;
    background-color: #f7913f;
  }
}

.pop-product-item {
  padding: 20px;

  &-img {
    width: 80px;
    height: 80px;
    border-radius: 8px;
  }

  &-title {
    font-size: 18px;
    font-weight: 600;
    padding-top: 15px;
  }

  &-point {
    padding-top: 5px;
    font-size: 17px;
    color: #fbae54;
  }

  &-count {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-tiplab {
    color: #333;
    font-size: 15px;
  }
}

.coupon-bg {
  position: relative;
  background-color: #fbf3d4;
  margin: 40px 15px 0;
  padding: 20px 0;
  border-radius: 10px;
  min-height: 120px;

  &-header {
    position: absolute;
    width: 200px;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.coupon-bg-panel {
  padding: 20px 0px 0px;
  margin: 0 15px 0;
}

.coupon-bg-img {
  min-height: 80px;
  background-image: url("../../static/images/v3/coupon-single-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-bottom: 10px;
}

.coupon-change-item {
  margin-left: 5%;
  width: 27%;
  min-height: 80px;
  display: inline-block;

  &-points {
    font-size: 13px;
    text-align: center;
  }

  &-step {
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.exchange-record {
  position: absolute;
  right: 30px;
  bottom: 15px;

  &-icon {
    width: 20px;
    height: 20px;
  }
}

.exchange-rules {
  position: relative;
  background: linear-gradient(to right, #fdfffe, #fbf9e0);
  margin: 20px 15px;
  padding: 10px 5px;
  border-radius: 15px;
  z-index: 99;

  &-panel {
    border-radius: 10px;
    padding: 10px 10px 10px;

    &-title {
      width: 100%;
      font-weight: 500;
      text-align: center;
    }
  }

  &-info {
    padding-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    line-height: 160%;

    &-tip {
      font-size: 13px;
      color: #666;
    }

    &-img {
      width: 96%;
      padding-left: 2%;
      padding-top: 10px;
      padding-bottom: 0px;
    }
  }
}

.top-userinfo-header {
  position: relative;
  padding: 0 10px 0;
  margin-top: -25px;

  &-content {
    display: flex;
    align-items: center;
  }

  &_avatar {
    width: 48px;
    height: 48px;
  }

  &_detail {
    padding-left: 10px;
    color: #df5214;
    font-weight: 600;

    &_panel {
      display: flex;
      align-items: center;
    }

    &_phone {
      font-size: 16px;
    }

    &_points {
      padding-top: 2px;
      font-size: 11px;
    }

    &_logout {
      margin-left: 12px;
      border-radius: 2px;
      padding: 1px 5px;
      font-size: 12px;
      border: 1px solid #df5214;
    }
  }

  &_ex {
    position: absolute;
    right: 10px;
    top: 5px;
    text-align: center;
    height: 26px;
    line-height: 26px;
    width: 80px;
    font-size: 14px;
    border-radius: 13px;
    color: #df5214;
    background: linear-gradient(to right, #fdfffe, #fbf9e0);
  }
}

/deep/ .van-step__circle-container {
  margin-top: 20px !important;
}

/deep/ .van-step__line {
  margin-top: 20px !important;
}

/deep/ .van-step__circle-container .van-icon {
  color: red !important;
}

/deep/ .van-submit-bar__tip-text {
  font-size: 15px;
}
</style>
